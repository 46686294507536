import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import * as VueGoogleMaps from "vue2-google-maps"


Vue.use(VueGoogleMaps, {
  load: {
      key: "AIzaSyCXfTCxOINNl23fKYrQYh8-858pSi9fRBk",
  },
});

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

//Vue.prototype.$url = 'https://herdel.info:8443/webapi/';
Vue.prototype.$url = 'https://rccsa.info:8443/webapi/';

 const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#2196f3',
};

Vue.use(VueSweetalert2, options);

new Vue({
  router,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')
